@import "../../../resources/styles/colors";
@import "../../../resources/styles/media";
@import "../../../resources/styles/variables";

.spaceIcon {
    background-color: @whiteConstant;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3/16rem;
    border-width: 1/16rem;
    border-style: solid;
    border-color: @whiteConstant;
    overflow: hidden;
    img {
        max-width: 24/16rem;
        max-height: 24/16rem;
    }
}

.spaceMenu {
    background-color: @spaceMenuBackground;
    text-decoration: none;
    cursor: pointer;

    a {
        height: 100%;
        color: @spaceMenuText;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 @margin-double;

        &:hover {
            background-color: @navItemHover;
            color: @navTextHover;
        }
        &:active,
        &:focus {
            background-color: @navItemHover;
        }
    }
}

.title {
    margin-left: @margin-single;
    margin-right: @margin-single;
    white-space: nowrap;
    font-size: 1rem;
    max-width: 200/16rem;
    overflow: hidden;
    font-weight: 500;
}

.caret {
    padding: 0.4rem;
    font-size: 0.8rem;
}

@media (max-width: @screen-sm) {
    .title {
        display: none;
    }
}
