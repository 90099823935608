.wrapper {
    padding: 16px;
    font-size: 0.875rem;
    background-color: var(--secondaryBackground);
    a {
        font-size: 0.75rem;
    }
}

.headline {
    display: block;
    color: var(--primaryText);
    font-weight: 500;
    margin-bottom: 1px;
}

.content {
    display: block;
    color: var(--ternaryText);
    -webkit-font-smoothing: antialiased;
    // TODO: restore line below when docs link is active
    // margin-bottom: 8px;
}
