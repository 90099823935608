@import "../../../../resources/styles/variables";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/media";

.empty {
    font-size: 1rem;
    margin: 0 1rem;
    line-height: 1.5rem;
}

.paperLayoutOverride {
    > div:first-child {
        box-shadow: unset;
        border-top: 0.0625rem solid @divider;
        border-bottom: 0.0625rem solid @divider;
        margin: 0;
        border-radius: unset;
    }
}

.cardTitleContainer {
    display: flex;
    align-items: center;
    color: @primaryText;
    font-size: 0.8125rem;
    padding: 0 1rem;
    cursor: pointer; // Whole row is clickable, show cursor.
    .environmentIcon {
        display: flex;
        padding-right: 0.4rem;
        width:1.5rem;
        min-width: 1.5rem;
        height:auto;
    }
    .environmentName {
        color: @mainText;
        font-weight: @semibold;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
        white-space: nowrap;
        font-size: 1rem;
    }
    .environmentMachinesCount {
        color: @secondaryText;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .environmentSummaryCounts {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto; // This pulls it right.
        margin-right: 5rem; // Margin to avoid the absolute position caret.
        @media (max-width: @screen-sm) {
            display: none; // Too many table/padding issues at small res.
        }
        .summaryCount {
            margin: 0.25rem 0;
            padding-right: 0.4rem;
            display: flex;
            align-items: center;
            font-size: 0.8125rem !important; // Done on purpose to match design, this has to match for both the action button and the close button.
            text-transform: uppercase; // To match ternary action so this toggles seamlessly.
            & svg {
                margin: 0;
                margin-left: 0.7rem;
            }
        }
    }
    .environmentOverflowActions {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        right: 3rem; // Leave enough room for the down-arrow on the right.
        margin: auto;
        width: 3rem;
        height: 2.25rem;
    }
}

.machineIcon {
    width: 2.438rem;
    height: 2.563rem; // Always set height or the screen gets janky as images load.
    background-color: white;
    border-radius: 30px;
    padding: 0.5rem;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.healthStatusIconContainer {
    margin-right: 0.25rem;
    background: @logoBackground;
    border-radius: 1.875rem;
    border: 0.0625rem solid @logoBackground;
}

.healthStatusCardTitleContainer {
    display: flex;
    align-items: center;
    font-size: 0.8125rem;
    padding: 0;
    margin-left: 1.5rem;
    @media (max-width: @screen-sm) {
        margin-left: 0.75rem;
    }
    .healthStatusIconContainer {
        display: flex;
        min-width: 1rem;
        padding-right: 0.4rem;
    }
    .healthStatusName {
        color: @primaryText;
        font-weight: @semibold;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
    .healthStatusMachinesCount {
        color: @secondaryText;
        margin: 0.25rem 0;
        padding-right: 0.4rem;
    }
}

// SimpleExpander

.expandLink {
    width: 6rem;
    height: 1.19rem;
    font-family: Roboto;
    font-size: 0.875rem;
    line-height: 1.36rem;
    color: @secondaryText;
    margin-right: 1.5rem;
}

.linkContainer {
    width: 100%;
    text-align: right;
}

.hide {
    font-family: Roboto;
    font-size: 0.75rem;
    line-height: 1.83rem;
    text-align: left;
    color: @secondaryText;
    a {
        color: @mainText;
    }
    text-align: right;
}

.environmentDescription {
    font-size: 0.875rem;
}

.environmentSelectorContainer {
    width: 15.6rem !important; /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */
}