@import "../../resources/styles/colors.less";

.codeEditorContainer {
    position: relative;
    min-height: 15rem;
    border: 0.0625rem solid @divider;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.codeEditorContainerFullScreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    z-index: 1300;
    background: @paper0;
}

.exitFullScreen {
    position: fixed;
    z-index: 1301;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
}

.enterFullScreen {
    z-index: 9; // Needs to be less then the sticky components
    background-color: @secondaryBackground;
    border-bottom: 1px solid @divider;
    display: flex;
    justify-content: flex-end;
}

.hintHighlight {
    color: @hintHighlight;
    font-weight: bold;
}

:global {
    .ReactCodeMirror {
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 1rem;
        position: absolute;

        .CodeMirror-fullscreen {
            top: 0;
            height: 100% !important;
        }

        .CodeMirror {
            height: inherit;
            font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
            font-size: 1rem;
            background: @paper0 !important;
            color: @primaryText !important;
        }

        .readonly .CodeMirror-cursors {
            visibility: hidden !important;
        }

        .cm-s-default.CodeMirror {
            background-color: var(--paper0);
            color: var(--secondaryText);
        }

        .cm-s-default .CodeMirror-gutters {
            background: var(--primaryBackground);
            color: var(--primaryText);
            border: none;
        }

        .cm-s-default .CodeMirror-guttermarker,
        .cm-s-default .CodeMirror-guttermarker-subtle,
        .cm-s-default .CodeMirror-linenumber {
            color: var(--secondaryText);
        }

        .cm-s-default .CodeMirror-cursor {
            border-left-width: 1px;
            border-left-style: solid;
            border-left-color: var(--sunshineText);
        }

        .cm-s-default div.CodeMirror-selected {
            background: var(--codeHighlight);
        }

        .cm-s-default.CodeMirror-focused div.CodeMirror-selected {
            background: var(--codeHighlight);
        }

        .cm-s-default .CodeMirror-line::selection,
        .cm-s-default .CodeMirror-line > span::selection,
        .cm-s-default .CodeMirror-line > span > span::selection {
            background: var(--codeHighlight);
        }

        .cm-s-default .CodeMirror-line::-moz-selection,
        .cm-s-default .CodeMirror-line > span::-moz-selection,
        .cm-s-default .CodeMirror-line > span > span::-moz-selection {
            background: var(--codeHighlight);
        }

        .cm-s-default .CodeMirror-activeline-background {
            background: var(--primaryBackground);
        }

        .cm-s-default .cm-keyword {
            color: var(--codeTextBright);
        }

        .cm-s-default .cm-operator {
            color: var(--linkText);
        }

        .cm-s-default .cm-variable-2 {
            color: var(--infoText);
        }

        .cm-s-default .cm-variable-3,
        .cm-s-default .cm-type {
            color: var(--dangerText);
        }

        .cm-s-default .cm-builtin {
            color: var(--alertText);
        }

        .cm-s-default .cm-atom {
            color: var(--alertText);
        }

        .cm-s-default .cm-number {
            color: var(--dangerText);
        }

        .cm-s-default .cm-def {
            color: var(--infoText);
        }

        .cm-s-default .cm-string {
            color: var(--successText);
        }

        .cm-s-default .cm-string-2 {
            color: var(--dangerText);
        }

        .cm-s-default .cm-comment {
            color: var(--primaryText);
        }

        .cm-s-default .cm-variable {
            color: var(--dangerText);
        }

        .cm-s-default .cm-tag {
            color: var(--dangerText);
        }

        .cm-s-default .cm-meta {
            color: var(--alertText);
        }

        .cm-s-default .cm-attribute {
            color: var(--codeTextBright);
        }

        .cm-s-default .cm-property {
            color: var(--codeTextBright);
        }

        .cm-s-default .cm-qualifier {
            color: var(--sunshineText);
        }

        .cm-s-default .cm-variable-3,
        .cm-s-default .cm-type {
            color: var(--sunshineText);
        }

        .cm-s-default .cm-error {
            color: var(--blackConstant);
            background-color: var(--dangerConstant);
        }

        .cm-s-default .CodeMirror-matchingbracket {
            text-decoration: underline;
            color: var(--whiteConstant) !important;
        }

        .CodeMirror-foldmarker {
            color: @primaryText;
            text-shadow: @secondaryText 1px 1px 2px, @secondaryText -1px -1px 2px, @secondaryText 1px -1px 2px, @secondaryText -1px 1px 2px;
            font-family: arial;
            line-height: 0.3;
            cursor: pointer;
        }
        .CodeMirror-foldgutter {
            width: 0.7em;
        }
        .CodeMirror-foldgutter-open,
        .CodeMirror-foldgutter-folded {
            cursor: pointer;
        }
        .CodeMirror-foldgutter-open:after {
            content: "\25BE";
        }
        .CodeMirror-foldgutter-folded:after {
            content: "\25B8";
        }
        .CodeMirror-gutters {
            background-color: @paper1 !important;
        }

        .CodeMirror-linenumber {
            color: @secondaryText !important;
        }

        .CodeMirror-hints {
            z-index: 99999 !important;
            background: @paper2 !important;
            font-family: Consolas, Menlo, Monaco, "Courier New", monospace !important;
        }

        .CodeMirror-hint {
            color: @placeholder !important;
        }

        li.CodeMirror-hint-active {
            background: @hintActive !important;
            color: @placeholderActive !important;
        }



    }
}