@import "../../../../../../resources/styles/media.less";
@import "../../../../../../resources/styles/colors.less";

.container {
    margin-bottom: 1rem;
    font-size: 0.875rem;
}


.title {
    margin: 0;
}

.sectionHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    background: @secondaryBackground;
    padding: 0.725rem 2rem;
    min-height: 60px;
    margin-bottom: 0.5rem;
}

.listHeading {
    padding-bottom: 1rem;
    border-bottom: 0.0625rem solid @divider;
    color: @secondaryText;
}

.nothingToShow {
    color: @secondaryText;
}

.contentContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 2rem;
}

.listContainer {
    width: 50%;
    &:first-child {
        padding-right: 3rem;
        @media (max-width: @screen-md) {
            padding-right: 0px;
        }
    }
    &:last-child:not(:only-child) {
        padding-left: 3rem;
        @media (max-width: @screen-md) {
            padding-left: 0px;
        }
    }
    @media (max-width: @screen-md) {
        width: 100%;
    }
}

.listItem {
    min-height: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.375rem 0rem 0.25rem;
    &:first-child {
        padding-top: 0px;
    }
    &:not(:last-child) {
        border-bottom: 0.025rem solid @divider;
    }
    :first-child {
        font-weight: 500;
    }
}

.rowTitle {
    flex-grow: 1;
    padding-right: 1rem;
}

.rowValue {
    width: 100%;
    text-align: right;
}

.rowContainer {
    min-width: 80px;
    max-width: 80px;
    overflow-wrap: break-word;
    text-align: right;
}