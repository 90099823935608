@import "../../resources/styles/colors.less";

.logo {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: @logoBackground;
    border-radius: 60/16rem;
    overflow: hidden;
    .logo-image-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    img {
        height: auto;
        width: auto;
        padding: 0.25rem;
    }
    .disabled {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        /* filter doesn't work for IE11, known issue, we fallback to opacity only in this case */
        opacity: 0.4;
    }
}