@import "../../../../resources/styles/base-colors.less";
@import "../../../../resources/styles/colors.less";
@import "../../../../resources/styles/media.less";

.disabledMessage {
    color: @secondaryText;
    font-size: 0.775em;
    display: flex;
}

.errorMessage {
    color: @errorText;
    font-size: 0.775em;
    display: flex;
}

// TODO #team-config-as-code: Extract Snackbar styles to a reusable 'SnackbarContent' component
// All of this (except colours) is taken - warts and all - from the default Material UI styles,
// as passing in your own element removes all default styling :|
.snackbarContent {
    background-color: @darkBackground;
    color: @darkText;
    flex-grow: initial;
    min-width: 288px;
    display: flex;
    padding: 6px 16px;
    flex-wrap: wrap;
    font-size: 0.875rem;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
}

.snackbarMessage {
    width: 100%;
    text-align: center;
    padding: 8px 0;
}

