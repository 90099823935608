@import "../../../../../../resources/styles/colors";

.icon {
    margin-right: 0.250rem
}

.infoNote {
    color: @infoHeaderText;
}

.wizardStepper {
    padding: 0rem 1rem;
    border-bottom: 1px solid @divider;

    .wizardIconStepCompleted:global(.MuiStepIcon-root.MuiStepIcon-completed) {
        color: @primaryButtonBackground;
    }
}

.wizardContent {
    margin-top: 1rem;
    text-align: left;

    ul {
        padding-left: 2rem;
        margin-bottom: 0;
        list-style-type: disc;
    }
}

.wizardButton {
    margin-right: 10px;

    &:last-child {
        margin-right: 0px;
    }
}

.newChip {
    color: @whiteConstant;
    background-color: @successConstant;
    padding: 1/10rem 1/2rem;
    border-radius: 1rem;
    margin-right: 1/4rem;
    display: inline-block;
}

ul.migrationReviewList {
    margin-bottom: 1/2rem;
}

.lightPulse {
    animation: lightPulse 2s infinite;
}
@-webkit-keyframes lightPulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 @successConstant;
    }
    70% {
        -webkit-box-shadow: 0 0 0 0.3rem @transparent;
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 @transparent;
    }
}
@keyframes lightPulse {
    0% {
        -moz-box-shadow: 0 0 0 0 @successConstant;
        box-shadow: 0 0 0 0 @successConstant;
    }
    70% {
        -moz-box-shadow: 0 0 0 0.3rem @transparent;
        box-shadow: 0 0 0 0.3rem @transparent;
    }
    100% {
        -moz-box-shadow: 0 0 0 0 @transparent;
        box-shadow: 0 0 0 0 @transparent;
    }
}