@import "../../../../../resources/styles/variables";
@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

.stepList {
  display: flex;
  flex-direction: column;
  > :last-child {
    > .listItemLine, > .listItem .listItemLine {
      display: none;
    }
    .stepContainer {
      border-bottom: unset;
    }
  }
}

.isDisabled * {
  color: @disabledButtonText !important;
  }

.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 0 0.5rem 0;
  padding: 0;
  position: relative;
  overflow: visible;
  z-index: 0;
  transition: all 200ms ease;
  min-height: 2rem;
  background-color: transparent;
  max-width: 17.5rem;
  border-top: 0.0625rem solid transparent;
  border-bottom: 0.0625rem solid transparent;
  overflow-wrap: break-word;
  @media (max-width: @screen-sm) {
    max-width: unset;
  }
  .listItemLine {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    height: 100%;
    border-left: 0.0625rem solid @ternaryText;
  }
  .stepContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.5rem 0;
    margin-left: 0.5rem;
    width: 100%;
    .stepNumber {
      font-weight: @bold;
      color: @primaryText;
      margin: 0 0.25rem 0 0;
      font-size: 0.875rem;
      line-height: 1rem;
    }
    .stepName {
      color: @primaryText;
      font-weight: @normal;
      font-size: 0.875rem;
      line-height: 1rem;
      max-width: 11rem;
      @media (max-width: @screen-sm) {
        max-width: 16rem; // We still need a max-width for the ellipsis to work, but we have more room horizontally in mobile view.
      }
    }
  }
  .contextualActions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
  .contextualActionsDisabledFakeMenuIcon {
    opacity: 0.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 2.25rem;
    cursor: default;
    svg {
      fill: @iconNeutral !important;
    }
  }
  .childThatSitsAboveVerticalLine() {
    position: relative;
    z-index: 10;
    background-color: @stepLogoBackground;
  }
  .stepLogo {
    width: 2rem;
    height: 2rem;
    z-index: 1;
    flex-shrink: 0;
    border: 0.0625rem solid @divider;
    border-radius: 2rem;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    overflow: hidden;
    .childThatSitsAboveVerticalLine();
    svg {
      padding-left: 0.0625rem;
      width: auto;
      height: 60%;
    }
    img {
      border-radius: 1.4rem; // Needed to deal with square community logos.
      height: auto;
    }
  }
  .parallelContainer {
    padding: 0.25rem;
    border-radius: 0.625rem;
    position: absolute;
    top: -0.5rem; // This has to consider regular steps, step with long content and child steps.
    left: 1rem; // Be VERY careful here and re-test on all browsers and zoom scalings if you change this.
    display: flex;
    justify-content: center;
    z-index: 100;
    width: 1rem;
    height: 1rem;
    background: @disabledButtonBorder;
    align-items: center;
  }
  .parallelIcon {
    width: 0.75rem;
    height: 0.375rem;
    border-top: 0.125rem solid @iconNavy;
    border-bottom: 0.125rem solid @iconNavy;
    .content {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &:hover {
    background-color: @hover;
    .stepContainer {
      .stepNumber, .stepName {
        color: @linkTextHover;
      }
    }
  }
  &.isActive {
    .stepName {
      color: @mainText;
      font-weight: @bold;
    }
  }
}

.group {
  > .listItem {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
    border-bottom: 0.0625rem solid transparent;
    border-top: 0.0625rem solid transparent;
    align-items: stretch;
    .stepName,
    .stepNumber {
      font-size: 0.75rem;
    }
    .stepLogo {
      border-radius: 0;
      margin-left: 0.5rem;
      margin-top: 0;
      margin-bottom: 0;
      height: 100%;
      align-items: center;
      :first-child {
        background-color: transparent;
      }
    }
    .stepContainer {
      line-height: 1.25rem;
      border-bottom: 0.0625rem solid transparent;
    }
  }
  > a:first-child {
    margin-bottom: 0;
    margin-top: 0;
    .stepName,
    .stepNumber {
      font-size: 0.875rem;
    }
    .stepLogo {
      border-bottom: 0 solid transparent;
      background-color: @parentStepBackground;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      justify-content: center;
      svg {
        fill: @whiteConstant;
        width: auto;
        height: 100%;
        padding-left: 0;
      }
    }
    &:hover {
      svg {
        fill: @primaryDark;
      }
    }
  }
  > a:last-child {
    margin-top: 0;
    margin-bottom: 0.5rem;
    .stepLogo {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
      flex: 1;
    }
  }
  a:first-child.isDisabled > .stepWrapper > div > .stepLogo {
    background-color: @divider;
    .stepIcon {
      opacity: 1;
    }
  }
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  div:nth-child(2) {
    flex: 1;
  }
}

.listItemRipple {
  align-items: stretch!important; // Override MUI.
  padding: 0!important; // Override MUI.
}

.overflowButton {
  font-size: 1.375rem;
  width: 3rem !important;
  height: 2.25rem !important;
  padding: 0 !important;
  display:flex;
  align-items: center;
  text-decoration: none;
  color: @iconNeutral !important;

  &:visited {
    color: @iconNeutral;
  }

  &:hover {
    background-color:transparent !important;
    opacity: 0.5;
  }
}